import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton, TextField
} from "@mui/material";
import React, { useState } from "react";
import { Document } from "../../../../model/Document";
import { SourceDocument } from "../../../../model/SourceDocument";
import { Patient } from "../../../../model/Patient";
import { Facility } from "../../../../model/Facility";
import dayjs, { Dayjs } from "dayjs";
import { DocumentBuilder } from "../../../helpers/document/documentBuilder";
import { Button } from "../../../common/Button/Button";
import { Label } from "../../../common/Label/Label";
import classes from "./CreateDocumentDialog.module.scss";
import CloseIcon from '@mui/icons-material/Close';
import { Dropdown } from "../../../common/Dropdown/Dropdown";
import { DropdownOption } from "../../../common/Dropdown/DropdownOption/DropdownOption";
import { CustomDatePicker } from "../../../common/DatePicker/DatePicker";
import { Padding } from "@mui/icons-material";
import { minDate, maxDate } from "../../../../model/DateQuestion";

type Props = {
    sourceDocuments: SourceDocument[];
    isOpen: boolean;
    handleCreateDocument: (document: Document) => void;
    handleCancel: () => void;
    patientId: number;
    facilityId: number;
    facilityName: string;
    orgId: string;
    patient: Patient;
    facility: Facility;
};

export const CreateDocumentDialog: React.FC<Props> = (props: any) => {
    const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs());
    const [selectValue, setSelectValue] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);


    function resetScreen() {
        setSelectValue("");
        setDateValue(dayjs());
        setIsSubmitting(false);
    }

    function createDocument() {
        setIsSubmitting(true);
        if (!selectValue) {
            return;
        }
        if (!dateValue || !dateValue.isValid() || dateValue < dayjs(minDate) || dateValue > dayjs(maxDate)) return;
        let sourceDocument = props.sourceDocuments.find(
            (c) => c.id === +selectValue
        );
        let requestedDate = dateValue?.format("MM/DD/YYYY");

        let document = new DocumentBuilder()
            .setFacilityId(props.facilityId)
            .setFacilityName(props.facilityName)
            .setOrgId(props.orgId)
            .setPatientId(props.patientId)
            .setRequestDate(requestedDate)
            .setScreenDate(requestedDate)
            .setSourceDocument(sourceDocument)
            .setPatientInfo(props.patient)
            .setFacilityInfo(props.facility)
            .setSourceDocumentId(sourceDocument.id)
            .build();

        props.handleCreateDocument(document);
        resetScreen();
    }

    function cancel() {
        props.handleCancel();
        resetScreen();
    }

    return (
        <Dialog scroll="paper" fullWidth maxWidth="xs" open={props.isOpen}>
            <DialogTitle>
                <div className={classes.title_div}>
                    <Label styleName={classes.title}>
                        Add a new screen
                    </Label>
                    <IconButton aria-label="close" onClick={cancel}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent dividers>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        m: "auto",
                        width: "fit-content",
                    }}
                >
                    <CustomDatePicker
                        label="Request Date"
                        dateValue={dateValue}
                        onValueChanged={(newValue) => {
                            setIsSubmitting(false);
                            setDateValue(newValue);
                        }}
                        minDateErrorMsg="Date must be after 12/31/1899"
                        maxDateErrorMsg="Date must be before 01/01/2100"
                    />
                    {(!dateValue
                        || !dateValue.isValid()
                    ) && isSubmitting
                        ? (
                            <FormHelperText style={{ paddingLeft: '13px' }} error={true} >
                                Please enter a valid date.
                            </FormHelperText>
                        ) : null}
                    <FormControl error={!selectValue && isSubmitting}>
                        <Dropdown
                            sx={{ marginTop: 3 }}
                            onChange={(event) => {
                                setIsSubmitting(false);
                                setSelectValue(event.target.value)
                            }
                            }
                            value={selectValue}
                        >
                            {props.sourceDocuments?.map((a) => (
                                <DropdownOption key={a.id} value={a.id}>
                                    {a.documentTemplate.screenReason}
                                </DropdownOption>
                            ))}
                        </Dropdown>
                        {!selectValue && isSubmitting
                            ? (
                                <FormHelperText>
                                    Please select a therapy screen
                                </FormHelperText>
                            ) : null}
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button type="secondary" onClick={cancel}>
                    Cancel
                </Button>
                <Button type="primary" onClick={createDocument}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};
