import { useState } from "react";
import { AppBar, Box, IconButton, InputBase, Toolbar, Typography, alpha, styled } from "@mui/material";
import { AddCircleOutlineRounded, SettingsOutlined } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import classes from './MaintenanceAppBar.module.scss'
import { Label } from "../../../common/Label/Label";
import { Button } from "../../../common/Button/Button";

//#region Search Style 
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

type Props = {
    filterSourceDocuments,
    addNewScreen
};
//#endregion

export const MaintenanceAppBar: React.FC<Props> = (props) => {
    const [text, setText] = useState<string>('');

    function handleOnTextChanged(filterText: string) {
        setText(filterText);
        props.filterSourceDocuments(filterText);
    }

    function handleClearButtonClicked() {
        setText('')
        props.filterSourceDocuments();
    }

    function handleAddScreenButtonClicked() {
        props.addNewScreen();
    }

    return (
        <AppBar className={classes.appBar}>
            <Box className={classes.appBarContainer}>
                <Toolbar disableGutters>
                    <IconButton
                        className={classes.settingsIcon}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu">
                        <SettingsOutlined />
                    </IconButton>
                    <Label styleName={classes.header_title}>
                        Therapy Screens Maintenance
                    </Label>
                    <div className={classes.add_button}>
                        <Button onClick={handleAddScreenButtonClicked}>Create Screen</Button>
                    </div>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            value={text}
                            onChange={(e) => { handleOnTextChanged(e.target.value) }}
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        {text?.length > 0 && (
                            <IconButton onClick={handleClearButtonClicked}>
                                <CloseIcon color='disabled' />
                            </IconButton>
                        )}
                    </Search>
                </Toolbar>
            </Box>
        </AppBar>
    )
}